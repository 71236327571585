import './App.css';
import SectionOne from './SectionOne.js'
import SectionTwo from './SectionTwo.js'
import SectionThree from './SectionThree.js'
import SectionFour from './SectionFour.js'

function App() {
  return (
    <div className="big-container">
      <SectionOne/>
      <SectionTwo/>
      <SectionThree/>
      <SectionFour/>
      
    </div>
  );
}

export default App;
